<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin phiếu cho mượn máy'">
      <template v-slot:preview>
        <b-skeleton-wrapper :loading="!renderComponent">
          <!-- Loader -->
          <template #loading>
            <table-loader />
          </template>
          <!-- warning area -->
          <b-row v-if="billNumber">
            <b-col>
              <div
                class="alert-header"
                role="alert"
                style="width: 100%"
              >
                <div class="left-alert">
                  <inline-svg
                    src="media/svg/icons/other/alert-triangle.svg"
                    width="24"
                    height="24"
                  ></inline-svg>
                </div>
                <div class="right-alert text-dark">
                  <p>
                    Từ đơn bảo hành:
                    <span
                      class="text-primary cursor-pointer"
                      @click="viewBill"
                    >{{ billNumber }}</span>
                  </p>
                  <p v-if="createdAt">
                    Ngày cho mượn: <span class="text-primary">{{ createdAt }}</span>
                  </p>
                  <p v-if="stockWarning">
                    Số ngày nhắc nhở: <span class="text-primary">{{ stockWarning.numberRemindDay }} ngày</span> (trước ngày trả)
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="stockWarning && stockWarning.isExceed">
            <b-col>
              <div
                class="warning-header"
                role="alert"
              >
                <div class="left-warning">
                  <inline-svg
                    src="media/svg/icons/other/warning-circle.svg"
                    width="24"
                    height="24"
                  ></inline-svg>
                </div>
                <div class="right-warning">
                  <p>Vượt quá thời hạn nhắc nhở</p>
                  <p>{{ stockWarning.message }}</p>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- Input area -->
          <b-row class="mb-5">
            <!-- LEFT INPUT CONTROLS -->
            <b-col
              lg="9"
              md="9"
              sm="12"
            >
              <b-row>
                <!-- Kho -->
                <b-col md="3">
                  <b-form-group class="required-control">
                    <label>Kho:</label>
                    <Autosuggest
                      :disabled="true"
                      :model="searchStore"
                      :suggestions="filteredOptions"
                      @change="onInputChange"
                      @select="onSelectedStore"
                      aria-describedby="input-store"
                      size="sm"
                      placeholder="kho"
                      :limit="10"
                    >
                      <template #custom="{suggestion}">
                        <div>
                          <span>{{ suggestion.item.suggestionName }}</span>
                        </div>
                      </template>
                    </Autosuggest>
                  </b-form-group>
                </b-col>
                <!-- Điện thoại -->
                <b-col md="3">
                  <b-form-group class="required-control">
                    <label>Điện thoại:</label>
                    <Autosuggest
                      :disabled="true"
                      :model="searchCustomer"
                      :suggestions="filteredOptionsCustomer"
                      @change="onInputChangCustomer"
                      @select="onSelectedCustomer"
                      aria-describedby="input-customer"
                      size="sm"
                      placeholder="điện thoại"
                      :limit="10"
                    >
                      <template #custom="{suggestion}">
                        <div>
                          <span>{{ suggestion.item.suggestionName }}</span>
                        </div>
                      </template>
                    </Autosuggest>
                  </b-form-group>
                </b-col>
                <!-- Họ tên KH -->
                <b-col md="3">
                  <b-form-group>
                    <label>Họ tên KH: </label>
                    <b-form-input
                      :disabled="true"
                      class="input-style"
                      size="sm"
                      type="text"
                      v-model="customerName"
                      placeholder="Họ tên khách hàng"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Địa chỉ -->
                <b-col md="3">
                  <b-form-group>
                    <label>Địa chỉ:</label>
                    <b-form-input
                      :disabled="true"
                      class="input-style"
                      size="sm"
                      type="text"
                      v-model="customerAddress"
                      placeholder="Địa chỉ khách hàng"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group class="required-control">
                    <label>Ngày trả:</label>
                    <date-picker
                      :disabled="isReturned"
                      placeholder="nhập ngày trả"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="returnDate"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label>Trạng thái:</label>
                    <b-form-select
                      :disabled="isCreating || isReturned"
                      v-model="selectedStatus"
                      :options="statusOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <b-form-group>
                    <label>Ghi chú:</label>
                    <b-form-textarea
                      size="sm"
                      v-model="description"
                      :placeholder="'Thêm nội dung ghi chú...'"
                      :rows="6"
                      :max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col lg="3">
              <div>
                <BillPayment
                  v-if="renderComponent"
                  :billData="paymentModel"
                  :excludes="excludes"
                  v-on:receive-payment-mul="onUpdatePaymentV2"
                />
              </div>
            </b-col>
          </b-row>
          <!-- list product -->
          <span class="font-weight-bolder">Danh sách sản phẩm:</span>
          <b-row>
            <b-col
              lg="3"
              md="3"
              sm="12"
              class="pr-0"
            >
              <b-form-select
                class="select-style"
                v-model="selectTypeSearch"
                :options="listTypeSearch"
                size="sm"
                value-field="id"
                text-field="name"
                @change="onChangeSearchType"
                :disabled="!editable"
              ></b-form-select>
            </b-col>
            <b-col
              lg="9"
              md="9"
              sm="12"
              class="pl-0"
            >
              <Autosuggest
                :disabled="isReturned"
                :model="searchProduct"
                :suggestions="filteredOptionsProduct"
                @change="onInputChangProduct"
                @select="onSelectedProduct"
                aria-describedby="input-product"
                size="sm"
                placeholder="IMEI sản phẩm"
                :limit="10"
              >
                <template #custom="{suggestion}">
                  <div>
                    <span>{{ suggestion.item.productName }}</span>
                  </div>
                </template>
              </Autosuggest>
            </b-col>
          </b-row>
          <table class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          ">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="title-center"
                  style="width: 15%"
                >
                  Tên sản phẩm
                </th>
                <th
                  scope="col"
                  class="title-center"
                  style="width: 5%"
                >Tồn</th>
                <th
                  scope="col"
                  class="title-center"
                  style="width: 20%"
                >IMEI</th>
                <th
                  scope="col"
                  class="title-center"
                  style="width: 10%"
                >
                  Số lượng
                </th>
                <th
                  scope="col"
                  class="title-center"
                  style="width: 18%"
                >
                  Đơn giá
                </th>
                <th
                  scope="col"
                  class="title-center"
                  style="width: 14%"
                >
                  Thành tiền
                </th>
                <th
                  scope="col"
                  class="title-center"
                  style="width: 13%"
                >
                  Chiết khấu
                </th>
                <th
                  scope="col"
                  class="title-center"
                  style="width: 5%"
                  v-if="editable"
                ></th>
                <th v-if="!isReturned"></th>
              </tr>
            </thead>

            <tbody
              v-for="item in listProductStock"
              :key="item.id"
            >
              <ImportStock
                :productItem="item"
                :editState="editable"
                :deleteState="!isReturned"
                @deleteItem="deleteItemInArray"
                @update="updateItem"
              />
            </tbody>
            <tfoot>
              <tr>
                <td
                  :colspan="3"
                  class="font-weight-bolder"
                >Tổng</td>
                <td>{{ totalQuantity }}</td>
                <td></td>
                <td>{{ formatPrice(totalPrice) }}</td>
                <td>
                  {{ formatPrice(totalDiscount) }}
                </td>
              </tr>
            </tfoot>
          </table>
          <!-- submit area -->
          <div class="mb-5 mt-5 submit-container">
            <b-button
              v-show="listProductStock.length > 0"
              variant="primary"
              size="sm"
              @click="onSubmit"
            >
              Lưu
            </b-button>
            <router-link
              to="/lending-stock"
              tag="button"
            >
              <b-button
                variant="secondary"
                size="sm"
              >
                Trở về
              </b-button>
            </router-link>
          </div>
        </b-skeleton-wrapper>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
label {
  font-weight: 500;
}

.alert-header,
.warning-header {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  line-height: 2rem;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1.5rem;
}

.warning-header {
  background: #fcf1ef;
  color: #333238;
}

.right-warning,
.right-alert {
  display: flex;
  flex-direction: column;
}

.warning-header p,
.alert-header p {
  margin-bottom: 0;
}

.warning-header p:first-child {
  font-weight: 500;
  font-size: 1.125rem;
}

.alert-header {
  color: #856404;
  background-color: #fff3cd;
}
.container /deep/ .label-input {
  padding: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  color: #3f4254;
}
.submit-container button {
  width: 80px;
  font-weight: 500;
}
.submit-container button:first-child {
  margin-right: 10px;
}
table tfoot td {
  text-align: right;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '@/view/components/ImportStock';
import {
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
  makeToastFaile,
  formatPrice,
} from '@/utils/common';
import { TIME_TRIGGER } from '@/utils/constants';
import BillPayment from '@/view/components/bills/BillPayment.vue';
import TableLoader from '@/view/components/common/TableLoader.vue';
import { PAYMENT_TYPE, LENDING_SLIP_STATUS, PRODUCT_TYPE } from '@/utils/enum';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import timeUtils from '@/utils/date';
import debounce from 'debounce';

export default {
  components: {
    TableLoader,
    Autosuggest,
    BillPayment,
    KTCodePreview,
    ImportStock,
  },
  data() {
    return {
      status: 0,
      editable: false,
      createdAt: null,
      stockWarning: null,
      renderComponent: false,
      billData: {
        storeId: null,
      },
      selectedStatus: 1,
      statusOptions: [
        { value: 1, text: 'Đang cho mượn' },
        { value: 2, text: 'Đã trả máy' },
      ],
      action: '',
      billNumber: null,
      returnDate: moment().add(15, 'days').format('DD/MM/YYYY'),
      dpConfigs: timeUtils.DP_CONFIG,
      excludes: {
        fields: [],
        paymentMethods: [PAYMENT_TYPE.INSTALLMENT, PAYMENT_TYPE.REFUND],
      },
      excludePaymentMethods: [PAYMENT_TYPE.INSTALLMENT, PAYMENT_TYPE.REFUND],
      paymentModel: {
        storeId: null,
        paymentInfo: {
          //tiền mặt
          cashAmount: 0,
          cashAccountantName: '',
          cashAccountId: null,
          //chuyển khoản
          transferAmount: 0,
          transferAccountantName: '',
          transferAccountantId: null,
          transferReferenceCode: '',
          //quẹt thẻ
          creditAmount: 0,
          creditAccountantName: '',
          creditAccountantId: null,
          creditCode: '',
          creditCardNo: '',
          //phí quẹt thẻ
          creditCardFee: 0,
          creditFeeAccountantName: '',
          creditFeeAccountantId: null,
          payCreditFeeType: 1,
          //trả góp
          installedMoneyAmount: 0,
          installmentCode: '',
          installmentAccountName: '',
          installMoneyAccountId: null,
          customerIndentifyNo: '',
          installmentProgramMonthNo: 0,
        },
        installments: [],
        credits: [],
        transfer: [],
        cash: [],
        refunds: [],
      },
      isNew: true,
      listProduct: [],
      description: '',
      filteredOptionsProduct: [],
      optionsProduct: [],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 2,
          name: 'Tìm theo IMEI',
        },
      ],
      selectTypeSearch: 2,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [],
      searchStore: '',
      inventorySelected: null,
      formater: {
        currency: currencyMask,
      },
      isSearching: false,
      customerId: null,
      customerName: '',
      customerAddress: '',
      filteredOptionsCustomer: [],
      optionsCustomer: [],
      searchCustomer: '',
      selectedClassCustomer: null,
      id: null,
    };
  },
  created() {
    this.setDatePickerOptions();
    this.fetchStoreByUser();
    this.id = this.$route.query.id || null;
    this.billNumber = this.$route.query.billNumber || null;
    this.action = this.$route.query.action || 'update';
    if (this.billNumber && this.action === 'create') {
      this.getBillById();
    }
    if (this.id) {
      this.getStockById();
    }
  },
  mounted() {
    const title = this.id
      ? 'Thông tin phiếu cho mượn máy'
      : 'Tạo phiếu cho mượn máy';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu cho mượn máy', route: '/lending-stock' },
      { title: title },
    ]);
  },
  computed: {
    isCreating() {
      return this.action === 'create';
    },
    isReturned() {
      return this.status === LENDING_SLIP_STATUS.RETURNED;
    },
    totalPrice() {
      return this.listProductStock.reduce((total, product) => {
        const price = unMaskPrice(product.price);
        const discount = unMaskPrice(product.discount);
        return total + price * product.quantity - discount;
      }, 0);
    },
    totalDiscount() {
      return this.listProductStock.reduce((total, product) => {
        return total + unMaskPrice(product.discount) * 1;
      }, 0);
    },
    totalQuantity() {
      return this.listProductStock.reduce((total, product) => {
        return total + product.quantity * 1;
      }, 0);
    },
  },
  methods: {
    setDatePickerOptions() {
      this.dpConfigs.date.minDate = moment();
      this.dpConfigs.date.maxDate = moment().add(1, 'month');
    },
    viewBill() {
      if (!this.billNumber) {
        return;
      }
      this.$router.push({
        name: 'add-warranty-bill',
        query: { billId: this.billNumber, action: 'update' },
      });
    },
    formatPrice,
    onUpdatePaymentV2(items = [], type) {
      const payments = items.reduce((result, current) => {
        result.push({
          ...current,
          paymentAmount: unMaskPrice(current.paymentAmount),
        });
        return result;
      }, []);
      switch (type) {
        case PAYMENT_TYPE.INSTALLMENT: {
          this.paymentModel.installments = payments;
          break;
        }
        case PAYMENT_TYPE.CREDIT: {
          this.paymentModel.credits = payments;
          break;
        }
        case PAYMENT_TYPE.TRANSFER: {
          this.paymentModel.transfer = payments;
          break;
        }
        case PAYMENT_TYPE.CASH: {
          this.paymentModel.cash = payments;
          break;
        }
      }
    },
    getBillById() {
      if (!this.billNumber) {
        return;
      }
      ApiService.query('/bills/' + this.billNumber).then((res) => {
        if (res.data.status !== 1) {
          return;
        }
        const {
          storeId,
          storeName,
          customerId,
          customerPhone,
          customerName,
          customerAddress,
        } = res.data.data;
        this.searchStore = storeName;
        this.inventorySelected = storeId;
        this.paymentModel.storeId = storeId;
        this.searchCustomer = customerPhone;
        this.selectedClassCustomer = customerId;
        this.customerId = customerId;
        this.customerName = customerName;
        this.customerAddress = customerAddress;
        setTimeout(() => {
          this.renderComponent = true;
        }, TIME_TRIGGER);
      });
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.productName;
      let imeiCode = '';
      if (this.selectTypeSearch === 2) {
        imeiCode = this.selectedClassProduct.imeiCode
          ? this.selectedClassProduct.imeiCode
          : '';
      }
      const pro = {
        id: this.$uuid.v4(),
        name: this.selectedClassProduct.productName,
        totalQuantityInStock: this.selectedClassProduct.quantityInStock
          ? this.selectedClassProduct.quantityInStock
          : 0,
        IMEI: imeiCode,
        proId: this.selectedClassProduct.productId,
        price: this.selectedClassProduct.sellingPrice,
        quantity: 1,
        discount: 0,
        totalPrice: this.selectedClassProduct.sellingPrice,
        productType: this.selectedClassProduct.productType,
        productCode: this.selectedClassProduct.productCode,
        barCode: this.selectedClassProduct.barCode,
      };
      this.listProductStock.unshift(pro);
      this.searchProduct = '';
    },
    onInputChangProduct(text = '') {
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    validate() {
      let result = {
        isValid: true,
        message: '',
      };
      if (!this.searchStore || this.searchStore.trim() === '') {
        result.isValid = false;
        result.message = 'Vui lòng chọn kho!';
        return result;
      }
      if (!this.searchCustomer.trim()) {
        result.isValid = false;
        result.message = 'Vui lòng nhập SĐT khách hàng!';
        return result;
      }

      return result;
    },
    mapPayload() {
      const listPro = this.listProductStock.map((element) => {
        return {
          id: element.id || null,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: 1,
          unitPrice: unMaskPrice(element.price),
          discount: unMaskPrice(element.discount),
          productType: PRODUCT_TYPE.PRODUCT_IMEI,
        };
      });
      const data = {
        id: this.id,
        customerId: this.customerId,
        storeId: this.inventorySelected,
        customerName: this.customerName,
        customerMobile: this.searchCustomer,
        customerAddress: this.customerAddress,
        description: this.description,
        cashAmount: this.paymentModel.paymentInfo.cashAmount,
        listProduct: listPro,
        status: this.selectedStatus,
        returnDate: this.returnDate
          ? moment(this.returnDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        billId: this.billNumber,
        totalAmount: this.totalPrice,
        payments: this.paymentModel,
      };

      return data;
    },
    create(data) {
      ApiService.post('stock-slips/holding-stock-slip', data)
        .then((response) => {
          const { status, message } = response.data;
          this.isNew = true;
          if (status === 1) {
            makeToastSuccess(message);
            setTimeout(() => {
              this.$router.push({
                path: '/lending-stock',
              });
            }, TIME_TRIGGER);
          } else {
            makeToastFaile(message);
            this.isNew = true;
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
            this.isNew = true;
          }
        });
    },
    update(data) {
      ApiService.put('stock-slips/lending', data)
        .then((response) => {
          const { status, message } = response.data;
          this.isNew = true;
          if (status === 1) {
            makeToastSuccess(message);
            this.$router.push({
              path: '/lending-stock',
            });
          } else {
            makeToastFaile(message);
            this.isNew = true;
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
            this.isNew = true;
          }
        });
    },
    onSubmit: async function () {
      // validate
      const validateResult = this.validate();
      if (!validateResult.isValid) {
        return makeToastFaile(validateResult.message);
      }
      // mapping submit data
      const data = this.mapPayload();
      // call api
      if (this.isNew === true) {
        this.isNew = false;
        if (!this.id) {
          this.create(data);
        } else {
          this.update(data);
        }
      }
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = item.quantity;
          this.listProductStock[index].discount = unMaskPrice(item.discount);
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
    },
    fetchProductImei(textSearch) {
      this.listProduct = [];
      ApiService.get(
        `productSearch/search-by-imei?storeId=${this.inventorySelected}&imeiCode=${textSearch}`,
      ).then(({ data }) => {
        this.optionsProduct = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          const nextSearchProduct = this.searchProduct;
          this.fetchProductImei(nextSearchProduct);
        }
      });
    },
    onChangeStore() {
      this.listProductStock = [];
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.onChangeStore();
    },
    onInputChange(text) {
      this.searchStore = text;
      const filteredData = this.options
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = filteredData;
    },
    searchProductAPI() {
      if (!this.isSearching) {
        const textSearch = this.searchProduct.trim();
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei(textSearch);
        }
      }
    },
    debounceInputProduct: debounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStore = stores[0].name;
            this.inventorySelected = stores[0].id;
            this.inputPropStore.disabled = true;
          } else {
            this.options = [];
            stores.map((element) => {
              const store = {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: `${element.name} (${element.shortName})`,
              };
              this.options.push(store);
            });
            this.filteredOptions = [...this.options];
          }
        }
      });
    },
    getCustomerInfoById() {
      ApiService.get(`customer/${this.selectedClassCustomer}`).then((data) => {
        const customerInfo = data.data.data;
        this.customerName = customerInfo.fullName;
        this.customerAddress = customerInfo.address || '';
        this.searchCustomer = customerInfo.phoneNo;
      });
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.selectedClassCustomer = this.customerId = option.item.id;
      this.getCustomerInfoById();
    },
    onInputChangCustomer(text = '') {
      this.searchCustomer = text;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = this.optionsCustomer;
      this.debounceInput();
    },
    debounceInput: debounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      let text = this.searchCustomer;
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          this.listCustomer = customers;
          customers.map((element) => {
            let cus = {
              id: element.id,
              phoneNo: element.phoneNo,
              fullName: element.fullName,
              suggestionName: element.phoneNo + ' - ' + element.fullName,
            };
            this.optionsCustomer.push(cus);
          });
          this.filteredOptionsCustomer = [...this.optionsCustomer];
        },
      );
    },
    onChangeSearchType() {
      this.debounceInputProduct();
    },
    getStockById() {
      ApiService.get(`warranty-stock/${this.id}`).then(({ data }) => {
        const dataRes = data.data;
        this.codeStockBill = dataRes.code;
        this.inventorySelected = dataRes.storeId;
        this.paymentModel.storeId = dataRes.storeId;
        this.billNumber = dataRes.billId || null;
        this.searchStore = dataRes.storeName;
        this.selectedClassCustomer = dataRes.providerId;
        this.customerName = dataRes.customerName;
        this.searchCustomer = dataRes.customerPhone;
        this.customerAddress = dataRes.customerAddress;
        this.description = dataRes.description;
        this.billNumber = dataRes.billId;
        this.stockWarning = dataRes.stockWarning;
        this.returnDate = dataRes.returnDate;
        this.createdAt = dataRes.createdAt;
        this.status = dataRes.status;
        this.selectedStatus = dataRes.status;
        this.paymentModel.installments = dataRes.payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.INSTALLMENT,
        );
        this.paymentModel.credits = dataRes.payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.CREDIT,
        );
        this.paymentModel.transfer = dataRes.payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.TRANSFER,
        );
        this.paymentModel.cash = dataRes.payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.CASH,
        );
        setTimeout(() => {
          this.renderComponent = true;
        }, TIME_TRIGGER);
        this.listProductStock = [];
        this.listProductStock = dataRes.listDetail.map((element) => {
          return {
            id: element.id,
            name: element.productName,
            IMEI: element.productImei,
            proId: element.productId,
            price: element.unitPrice,
            quantity: element.quantity,
            totalPrice: element.totalAmount,
            productType: element.productType,
            formatImei: element.productImei,
            totalQuantityInStock: element.totalQuantityInStock || 0,
            discount: element.discount || 0,
            barCode: element.barCode || '',
            productCode: element.productCode || '',
          };
        });
      });
    },
  },
};
</script>
