<template>
  <div class="card-body">
    <b-row>
      <b-col md="9">
        <div class="mb-4 d-flex d-inline">
          <b-skeleton
            animation="wave"
            width="18%"
            class="mr-2"
          ></b-skeleton>
          <b-skeleton
            animation="wave"
            width="82%"
          ></b-skeleton>
        </div>
        <b-skeleton-table
          :rows="4"
          :columns="4"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
        <b-row>
          <b-col md="4">
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
          </b-col>
          <b-col md="4">
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
          </b-col>
          <b-col md="4">
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="50%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="100%"
              class="mb-4"
            ></b-skeleton>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3">
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="50%"
          class="mb-4"
        ></b-skeleton>
        <b-skeleton
          animation="fade"
          width="100%"
          class="mb-4"
        ></b-skeleton>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>